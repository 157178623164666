import React from 'react'
import BHRLogo from '../assets/Logo-BHR-azul.svg'
import LDLogo from '../assets/LD-logo.svg'

function Header() {
    return (
        <header>
            <img src={BHRLogo} alt="BHR-logo" />
            <div className="container__right">
                <img src={LDLogo} alt="LD-logo" />
            </div>
        </header>
    )
}

export default Header
