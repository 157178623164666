import React from 'react'

function Footer() {
    return (
        <div className="footer">
            <a href="https://bhrmx.com/aviso-de-privacidad/" target="_blank" rel="noopener noreferrer">Aviso de Privacidad</a>
            <div>COPYRIGHT (C) 2021 BHR Mexico. TODOS LOS DERECHOS RESERVADOS.</div>
        </div>
    )
}

export default Footer
