import React, { useContext, useReducer, createContext } from 'react'
// import { initialState } from './reducer';

export const AppStateContext = createContext();

export const useAppState = () => useContext(AppStateContext);

function AppContext({ children, reducer, initialState }) {
    return (
        <AppStateContext.Provider value={useReducer(reducer, initialState)}>
            {children}
        </AppStateContext.Provider>
    )
}

export default AppContext
