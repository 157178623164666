import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../components/axios';
import NewZones from '../components/NewZones';
import { SAVE_COMPANY, SAVE_ZONES } from '../utils/actions';
import { useAppState } from '../utils/AppContext';

function AdminNewCompany() {
    const [{ userInfo, saveZonesData }, dispatch] = useAppState();

    const [newCompanyclicks, setNewCompanyclicks] = useState([1]);
    const [newZonesClicks, setnewZonesClicks] = useState([]);
    const [newUbicationsClicks, setNewUbicationsClicks] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [companyName, setCompanyName] = useState("");
    const [saveCompanyData, setSaveCompanyData] = useState(false);
    const [finishedCompany, setFinishedCompany] = useState([]);
    const [disabled, setDisabled] = useState(true);

    const departmentsRef = useRef(null);
    const globalRef = useRef(null);
    const groupNameInputRef = useRef(null);

    const history = useHistory();

    useEffect(() => {
        if(!userInfo.status) {
            alert('Por favor ingresa con tu correo y contraseña');
            history.replace('/bhr-adminSignin');
        } else {
            const fetchData = async () => {
                try {
                    const response = await axios.get('/api/allData');
                    setCompanyOptions(response.data);
                } catch (error) {
                    alert(error.response.data.message);
                    history.push('/bhr-adminDashboard');
                }
            };
            fetchData()
            dispatch({
                type: SAVE_COMPANY,
                saveCompanyInfo: false
            })
        }

        return () => userInfo.status;
        
    }, [userInfo.status, history, dispatch]);

    const createNewGroup = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const newGroupName = groupNameInputRef.current.value;
        const newGroupData = {
            newGroupName,
            finishedCompany
        }
        if(newGroupName === '') {
            alert('Por favor pon un nombre al grupo');
            return
        } else {
            try {
                const { data } = await axios.post('/api/createGroup', newGroupData);
                alert(data);
                history.replace('/bhr-adminDashboard')
            } catch (error) {
                alert(error.response.data.message);
                history.replace('/bhr-adminDashboard');
            }
        }
    }

    const addNewUbication = () => {
        const allUbications = [...newUbicationsClicks];
        const lastNumberArray = allUbications.pop() || 0;
        const lastNumber = lastNumberArray + 1;
        const newUbications = [...newUbicationsClicks, lastNumber];
        setNewUbicationsClicks(newUbications)
    }

    const eraseUbication = (index) => {
        const newUbications = [...newUbicationsClicks];
        const foundIndex = newUbicationsClicks.findIndex(element => element === index);
        newUbications.splice(foundIndex, 1);
        setNewUbicationsClicks(newUbications);
    }

    const addNewZone = () => {
        const allZones = [...newZonesClicks];
        const lastNumberArray = allZones.pop() || 0;
        const lastNumber = lastNumberArray + 1;
        const newZones = [...newZonesClicks, lastNumber];
        setnewZonesClicks(newZones)
    }

    const eraseZone = (index) => {
        const newZone = [...newZonesClicks];
        const foundIndex = newZonesClicks.findIndex(element => element === index);
        newZone.splice(foundIndex, 1);
        setnewZonesClicks(newZone);
    }

    const addNewCompany = () => {
        setNewCompanyclicks([1]);
        setNewUbicationsClicks([]);
        setDisabled(true);
        dispatch({
            type: SAVE_COMPANY,
            saveCompanyInfo: false
        })
        dispatch({
            type: SAVE_ZONES,
            newZone: []
        })
    }

    const eraseCompany = (index) => {
        // const newClicks = [...newCompanyclicks];
        // const foundIndex = newCompanyclicks.findIndex(element => element === index);
        // newClicks.splice(foundIndex, 1);
        // setNewCompanyclicks(newClicks);
        setNewCompanyclicks([]);
        setnewZonesClicks([]);
        setDisabled(false)
    }

    const saveCompany = async (e) => {
        e.preventDefault();
        const companyName = e.target.companyNameInput.value;
        const zone = newZonesClicks.length === 0 ? e.target.zoneInput.value : e.target.zoneInput[0].value;
        const firstUbication = e.target.ubicationInput[0] === undefined ? e.target.ubicationInput.value : e.target.ubicationInput[0].value;
        const firstDepartmentName = [];
        const firstDepartmentValue = [];
        const ubications = [];
        let zoneBlock;

        for(let i = 0; companyOptions.allAreas.length > i; i++) {
            const index = i;
            const firstDepartment = globalRef.current.elements[`departmentsInput${index}`][0] === undefined ? globalRef.current.elements[`departmentsInput${index}`] : globalRef.current.elements[`departmentsInput${index}`][0];
            if(firstDepartment.checked) {
                firstDepartmentValue.push(firstDepartment.value * 1);
                firstDepartmentName.push(firstDepartment.getAttribute('data-name'));
            }
            if(i === companyOptions.allAreas.length -1) {
                const newUbication = { ubicationName: firstUbication, departmentsNames: firstDepartmentName }
                ubications.push(newUbication);
            }
        }

        if(newUbicationsClicks.length > 0) {
            let departmentsValues = [];
            let departmentsNames = [];
            if(!newZonesClicks.length) {
                for(const ubication of newUbicationsClicks) {
                    const ubicationName = globalRef.current.elements[`ubicationInput${ubication}`].value;
                    for(let i = 0; companyOptions.allAreas.length > i; i++) {
                        const index = i;
                        if(globalRef.current.elements[`departmentsInput${index}`][ubication].checked) {
                            departmentsValues.push(globalRef.current.elements[`departmentsInput${index}`][ubication].value * 1);
                            departmentsNames.push(globalRef.current.elements[`departmentsInput${index}`][ubication].getAttribute('data-name'));
                        }
                    }
                    const newUbication = { ubicationName, departmentsNames }
                    ubications.push(newUbication);
                    departmentsNames = [];
                    departmentsValues = [];
                }
            } else {
                for(const ubication of newUbicationsClicks) {
                    const ubicationName = globalRef.current.elements[`ubicationInput${ubication}`][0] === undefined ? globalRef.current.elements[`ubicationInput${ubication}`].value : globalRef.current.elements[`ubicationInput${ubication}`][0].value;
                    for(let i = 0; companyOptions.allAreas.length > i; i++) {
                        const index = i;
                        if(globalRef.current.children[`ubicationInputBlock${ubication}`].children[`departmentsInputBlock${index}`].firstChild.children[`departmentsInput${index}`].checked) {
                            departmentsValues.push(globalRef.current.children[`ubicationInputBlock${ubication}`].children[`departmentsInputBlock${index}`].firstChild.children[`departmentsInput${index}`].value * 1);
                            departmentsNames.push(globalRef.current.children[`ubicationInputBlock${ubication}`].children[`departmentsInputBlock${index}`].firstChild.children[`departmentsInput${index}`].getAttribute('data-name'));
                        }
                    }
                    const newUbication = { ubicationName, departmentsNames }
                    ubications.push(newUbication);
                    departmentsNames = [];
                    departmentsValues = [];
                }
            }
        }
        
        zoneBlock = { zone, ubications }

        if(newZonesClicks.length !== 0) {
            await dispatch({
                type: SAVE_COMPANY,
                saveCompanyInfo: true
            })
        }
        await dispatch({
            type: SAVE_ZONES,
            newZone: zoneBlock
        })
        setCompanyName(companyName);
        setSaveCompanyData(true);
        setNewCompanyclicks([]);
        setnewZonesClicks([]);
        setDisabled(false);
    }

    useEffect(() => {
        if(saveCompanyData) {
            setFinishedCompany([...finishedCompany, { companyName, zoneBlock: saveZonesData }]);
            setCompanyName('');
            dispatch({
                type: SAVE_ZONES,
                newZone: []
            })
            setSaveCompanyData(false);
        }
    }, [saveCompanyData, companyName, dispatch, finishedCompany, saveZonesData])

    return (
        <div className='newCompanyContainer'>
            {finishedCompany.map((company, index) => (
                <div key={index} className="companyContainer__addedCompany">
                    <p>empresa: {company.companyName}</p>
                    {company.zoneBlock.map((zone, index) => (
                        <div key={index}>
                            <p>zona: {zone.zone}</p>
                            {zone.ubications.map((ubication, index) => (
                                <div key={index}>
                                    <p>ubicacion: {ubication.ubicationName}</p>
                                    {ubication.departmentsNames.map((department, index) => (
                                        <p key={index}>areas: {department}</p>
                                    ))}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
            <label className='newCompanyLabel' htmlFor="groupNameInput">Ingresa por favor el nombre del grupo</label>
            <input className='newCompanyInput' type="text" name='groupNameInput' ref={groupNameInputRef} />
            {newCompanyclicks.map((index) => (
                <form onSubmit={saveCompany} key={index} id={`globalForm${index}`} ref={globalRef} className="newCompanyForm">
                    <label className='newCompanyLabel' htmlFor="companyNameInput">Ingresa por favor el nombre de la empresa del grupo</label>
                    <input className='newCompanyInput' type="text" name="companyNameInput" />
                    <label htmlFor="zoneInput">Ingresa por favor la zona de la empresa</label>
                    <select name="zoneInput" id="zoneInput" className='newCompanySelect'>
                        <option value="">Seleccionar</option>
                        {companyOptions.allZones?.map((area, index) => (
                            <option key={index} value={area.nombre}>{area.nombre}</option>    
                        ))}
                    </select>
                    <label htmlFor="ubicationInput">Ingresa por favor el nombre de la ubicación</label>
                    <input type="text" name="ubicationInput" className='newCompanyInput' placeholder='Ubicación' />
                    
                    <div className='newCompanyDepTitle'>Haz click en las áreas a dar de alta en la ubicación</div>
                    {companyOptions.allAreas?.map((areas, index) => (
                        <div className='newCompany__depContainer' key={index} ref={departmentsRef}>
                            <label><input type="checkbox" name={`departmentsInput${index}`} id={`departmentsInput${index}`} value={areas.areasOpciones_id} data-name={areas.nombre}/>{areas.nombre}</label>
                        </div>
                    ))}
                    {newUbicationsClicks.map((index) => (
                        <div className='newCompany__newUbicationContainer' key={index} id={`ubicationInputBlock${index}`}>
                            <input className='newCompanyInput' type="text" name={`ubicationInput${index}`} placeholder='Ubicación' />
                            {companyOptions.allAreas?.map((areas, index) => (
                                <div className='newCompany__newUbicationDepContainer' key={index} id={`departmentsInputBlock${index}`}>
                                    <label><input type="checkbox" name={`departmentsInput${index}`} id={`departmentsInput${index}`} value={areas.areasOpciones_id} data-name={areas.nombre}/>{areas.nombre}</label>
                                </div>
                            ))}
                            <span className='newCompany__eraseUbication' onClick={() => eraseUbication(index)}>Borrar Ubicación</span>
                        </div>
                    ))}
                    <span onClick={addNewUbication} className="newCompany__addUbication">Agregar otra Ubicación</span>
                    {newZonesClicks.map((index) => (
                        <NewZones key={index} companyOptions={companyOptions} eraseZone={() => eraseZone(index)} />
                    ))}
                    <span onClick={addNewZone} className="newCompany__addZone">Agregar otra zona para la misma empresa</span>
                    <span onClick={eraseCompany} className="newCompany__deleteRegistry">Borrar Empresa</span>
                    <button type='submit' className='newCompany__saveCompany'>Guardar Empresa</button>
                </form>
            ))}
            <button className='newCompany__addNewButton' onClick={addNewCompany} disabled={disabled}>Nueva empresa</button>
            <button className='newCompany__addGroupButton' onClick={createNewGroup} disabled={finishedCompany.length < 1 || disabled}>Agregar grupo a la base de datos</button>
        </div>
    )
}

export default AdminNewCompany;
