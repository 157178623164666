import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import AddCompany from '../components/AddCompany';
import axios from '../components/axios';
import EditCompanyFields from '../components/EditCompanyFields';
import { SAVE_COMPANY } from '../utils/actions';
import { useAppState } from '../utils/AppContext';

function AdminEditCompany() {
    const [{ userInfo }, dispatch] = useAppState();
    const [groups, setGroups] = useState([]);
    const [error, setError] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [companyData, setCompanyData] = useState();
    const [allDepartments, setAllDepartments] = useState([]);
    const [editCompany, setEditCompany] = useState(false);
    const [createNewCompany, setCreateNewCompany] = useState(false);
    const [groupCodeData, setGroupCodeData] = useState();
    const [allZones, setAllZones] = useState();

    const history = useHistory();

    useEffect(() => {
        if(!userInfo.status) {
            alert('Por favor ingresa con tu correo y contraseña');
            history.replace('/bhr-adminSignin');
        } else {
            const fetchGroups = async () => {
                try {
                    const response = await axios.get('/api/fetchAllGroups');
                    setGroups(response.data.allGroups);
                    setAllDepartments(response.data.allAreas);
                } catch (error) {
                    alert(error.response.data.message);
                    history.push('/bhr-adminDashboard');
                }
            }
            fetchGroups();
            dispatch({
                type: SAVE_COMPANY,
                saveCompanyInfo: false
            })
        }

        return () => userInfo.status;

    }, [history, userInfo.status, dispatch])

    const searchGroup = async (e) => {
        e.preventDefault();
        const groupCode = e.target.groupName.value;
        if(groupCode === '') {
            setError(true);
            return
        } else {
            setError(false);
            try {
                const { data } = await axios.post('/api/fetchGroupData', {
                    groupCode
                });
                setAllZones(data[0].zonesOptions);
                setCompanyData(data);
                setShowButtons(true);
                setGroupCodeData(groupCode);
            } catch (error) {
                alert(error.response.data.message);
                history.push('/bhr-adminDashboard');
            }
        }
    }

    const newCompany = () => {
        setCreateNewCompany(true);
        setEditCompany(false);
    }

    const companyEdit = () => {
        setEditCompany(true);
        setCreateNewCompany(false);
    }
    
    return (
        <div className='adminEdit__container'>
            <form onSubmit={searchGroup} className="adminEdit__form">
                <select name="groupName" id="groupName" className='adminEdit__formSelect'>
                    <option value="">Seleccionar</option>
                    {groups?.map((group, index) => (
                        <option key={index} value={group.grupo_codeName}>{group.nombre}</option>
                    ))}
                </select>
                <button className='adminEdit__formButtonSubmit'>Buscar información</button>
                {error ? <p className='adminEdit__formError'>Por favor elige un grupo</p> : <p className='adminEdit__formInfo'>Por favor elige que acción deseas realizar</p>}
            </form>
            {showButtons &&
                <div className='adminEdit__optionsButtons'>
                    <button onClick={companyEdit} className="adminEdit__editCompany">Editar una compañía existente</button>
                    <button onClick={newCompany} className="adminEdit__addCompany">Agregar una nueva compañía al grupo</button>
                </div>
            }
            {editCompany &&
                <>
                    {companyData?.map((data, index) => (
                        <EditCompanyFields key={index} data={data} allDepartments={allDepartments} groupCodeData={groupCodeData} />
                    ))}
                </>
            }
            {createNewCompany &&
                <AddCompany allDepartments={allDepartments} groupCodeData={groupCodeData} allZones={allZones} />
            }
        </div>
    )
}

export default AdminEditCompany