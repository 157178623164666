import React from 'react'
import { useHistory } from 'react-router-dom';
import axios from '../components/axios';
import { USER_INFO } from '../utils/actions';
import { useAppState } from '../utils/AppContext';

function AdminSignIn() {
    // eslint-disable-next-line
    const [{}, dispatch] = useAppState();
    const history = useHistory();

    const submitUser = async (e) => {
        e.preventDefault();
        const correo = e.target.mail.value;
        const contraseña = e.target.password.value;
        if(correo.match(/([a-z]+)@([a-z]+)/gi)) {
            try {
                const response = await axios.post('/api/login', {
                    correo,
                    contraseña
                });
                dispatch({
                    type: USER_INFO,
                    userInfo: response.data
                })
                history.push('/bhr-adminDashboard')
            } catch (error) {
                alert("Usuario o contraseña incorrectos")
            }
        } else {
            alert("Por favor ingresa la terminación @ de tu correo")
        }
    }

    return (
        <div className="adminSignIn">
            <h1>Inicia Sesión</h1>
            <form onSubmit={submitUser} className="signInForm">
                <label htmlFor="mail">Correo</label>
                <input type="email" id="mail" />
                <label htmlFor="password">Contraseña</label>
                <input type="password" id="password" />
                <button type="submit">Ingresar</button>
            </form>
        </div>
    )
}

export default AdminSignIn
