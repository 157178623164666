import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useAppState } from '../utils/AppContext';
import axios from '../components/axios';

function AdminDeleteCompany() {
    const [{ userInfo }] = useAppState();

    const [groups, setGroups] = useState([]);
    const [error, setError] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if(!userInfo.status) {
            alert('Por favor ingresa con tu correo y contraseña');
            history.replace('/bhr-adminSignin');
        } else {
            const fetchGroups = async () => {
                try {
                    const response = await axios.get('/api/fetchAllGroups');
                    setGroups(response.data.allGroups);
                    // setAllDepartments(response.data.allAreas);
                } catch (error) {
                    alert(error.response.data.message);
                    history.push('/bhr-adminDashboard');
                }
            }
            fetchGroups()
        }

        return () => userInfo.status;

    }, [history, userInfo.status])

    const deleteGroup = async (e) => {
        e.preventDefault();
        const groupId = e.target.groupName.value;
        if(groupId === '') {
            setError(true);
            return
        } else {
            setError(false);
            try {
                const { data } = await axios.delete('/api/deleteGroupData', {
                    data: {
                        groupId
                    }
                });
                alert(data)
                history.replace('/bhr-adminDashboard');
            } catch (error) {
                alert(error.response.data.message);
                history.replace('/bhr-adminDashboard');
            }
        }
    }

    return (
        <div className='adminDelete__container'>
            <form onSubmit={deleteGroup} className="adminDelete__form">
                <p className='adminDelete__title'>Por favor elige el grupo que quieras borrar</p>
                <select className='adminDelete__select' name="groupName" id="groupName">
                    <option value="">Seleccionar</option>
                    {groups?.map((group, index) => (
                        <option key={index} value={group.grupoEmpresarial_id}>{group.nombre}</option>
                    ))}
                </select>
                <button className='adminDelete__deleteButton'>Borrar Grupo</button>
                {error ? <p className='adminDelete__error'>Por favor elige un grupo para proceder con la acción</p> : null}
            </form>
        </div>
    )
}

export default AdminDeleteCompany