import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import axios from '../components/axios';
import { useHistory, Link } from 'react-router-dom';
import { useAppState } from '../utils/AppContext';
import { COMPANIES_NAMES, COMPLAINT_INFO } from '../utils/actions';

function Company() {
    const [{ companies }, dispatch] = useAppState();
    const { companyId } = useParams();
    // eslint-disable-next-line
    const [companyInfo, setCompanyInfo] = useState([]);
    const [menus, setMenus] = useState(true);
    const history = useHistory();

    useEffect(() => {
        // If context state === undefined then do the fetch to the api again with the companyId param and setCompanyInfo to the response, else just set the companyInfo to the info that's already in the state
        // I'm doing this in case someone enters directly in the url with the companyId param and not from the main page
        const allCompanies = [];
        if(companies.length === 0){
            const id = companyId;
            const fetchCompany = async () => {
                try {
                    const { data } = await axios.post('/api/getCompanyId', {
                        companyId: id
                    });
                    for(let info of data.foundCompanies){
                        const companyData = {
                            name: info.nombre,
                            id: info.empresa_id
                        }
                        allCompanies.push(companyData);
                    }
                    dispatch({
                        type: COMPANIES_NAMES,
                        companies: allCompanies
                    });
                    const groupName = data.groupName;
                    setCompanyInfo({ allCompanies, groupName });
                } catch (error) {
                    alert('ID incorrecto, porfavor ingresa el ID de tu empresa');
                    history.replace('/')
                }
            }
            fetchCompany();
        } else {
            setCompanyInfo(companies);
        }
        
    }, [companies, companyId, dispatch, history])

    const submitFollowUp = async (e) => {
        e.preventDefault();
        const uri = e.target.user.value;
        try {
            const response = await axios.post('/api/complaintFollowup', {
                uri,
            });
            dispatch({
                type: COMPLAINT_INFO,
                complaintInfo: response.data
            });
            history.push(`/denuncia/${response.data.uri}`);
        } catch (err) {
            alert('ID interno erroneo, por favor ingresalo nuevamente')
        }
    }
    
    return (
        <div className="company">
            <section className="menus">
                <span onClick={() => setMenus(true)}>Inicio</span>
                <span onClick={() => setMenus(false)}>Seguimiento de denuncia</span>
            </section>
            {menus ? 
                <section className="main">
                    <h3>Bienvenido(a)</h3>
                    <div className="main__text">
                        <p className="text__p">
                            Ésta es una organización comprometida con la transparencia, la honestidad y con los colaboradores que laboran en ella.
                        </p>
                        <p className="text__p">
                            Agradecemos tu interés por la compañía y te invitamos a que si tienes algún caso que deba ser conocido o denunciado, lo ingreses en este sistema.
                        </p>
                        <p className="text__p">
                            Para asegurar el anonimato de los casos (si así lo deseas), este sitio está administrado por BHR ENW MÉXICO GROUP, S.C., además de que no hay rastreabilidad del acceso.
                        </p>
                    </div>
                    <Link className="main__link" to={`/${companyId}/denuncia`}>Ingresa una nueva denuncia</Link>
                </section>
                :
                <>
                    <form className="followup" onSubmit={submitFollowUp}>
                        <label htmlFor="user">Ingresa ID de la denuncia</label>
                        <input type="text" id="user" />
                        <button type="submit">Entrar</button>
                    </form>
                    <div className='companyPageText'>
                        <p>Reporta de forma anonima cualquier situación que vaya en contra de los principios y valores corporativos</p>
                        <p>El reporte de situacion o aspectos irregulares no debe entrañar miedos o temores, ya que nuestra Organización se compromete a proteger la identidad de las personas y la total confidencialidad de sus declaraciones. En ningún caso, la Organización entablará represalias o acciones en contra de quien con buena fe ha actuado en pro de una cultura ética</p>
                    </div>
                </>
            }
        </div>
    )
}

export default Company
