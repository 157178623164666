import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useAppState } from '../utils/AppContext';
import axios from '../components/axios';

function AdminEditUsers() {
    const [{ userInfo }] = useAppState();

    const [users, setUsers] = useState();
    const [createNewUser, setCreateNewUser] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const history = useHistory();
    
    useEffect(() => {
        if(!userInfo.status) {
            alert('Por favor ingresa con tu correo y contraseña');
            history.replace('/bhr-adminSignin');
        } else {
            const fetchGroups = async () => {
                try {
                    const response = await axios.get('/api/fetchAllUsers');
                    setUsers(response.data);
                } catch (error) {
                    alert(error.response.data.message);
                    history.push('/bhr-adminDashboard');
                }
            }
            fetchGroups()
        }

        return () => userInfo.status;
    }, [history, userInfo.status])

    const createUser = async (e) => {
        e.preventDefault();
        const mail = e.target.mail.value;
        const password = e.target.password.value;
        const role = e.target.role.value;
        console.log(mail)
        if(!mail.match(/([a-z]+)@([a-z]+)/gi)) {
            alert("Correo no tiene terminación @");
            return
        }
        if(mail === '' || password === '' || role === '') {
            alert("Por favor llena todos los campos");
            return
        }
        try {
            const { data } = await axios.post('/api/createNewUser', {
                mail,
                password,
                role
            });
            alert(data);
            history.replace('/bhr-adminDashboard');
        } catch (error) {
            alert(error.response.data.message);
            history.push('/bhr-adminDashboard');
        }
    }
    
    const deleteUser = async (e) => {
        e.preventDefault();
        try {
            const userId = e.target.userInfo.getAttribute('data-id');
            const { data } = await axios.delete('/api/deleteUser', {
                data: {
                    userId
                }
            });
            alert(data);
            history.replace('/bhr-adminDashboard');
        } catch (error) {
            alert(error.response.data.message);
            history.replace('/bhr-adminDashboard');
        }
    }
    
    return (
        <div className='adminEditUsers__container'>
            {users?.map((user, index) => {
                // return user.correo === 'adminlineadenuncia@bhrmx.com' ? null : <input key={index} type="text" value={user.correo} disabled />
                if(user.correo === 'adminlineadenuncia@bhrmx.com') {
                    return null
                } else {
                    return <form key={index} onSubmit={deleteUser} className="adminEditUsers__form">
                        <input className='adminEditUsers__formInput' type="text" value={user.correo} name='userInfo' data-id={user.id} disabled />
                        <button className='adminEditUsers__formDeleteButton' type='submit' disabled={!confirmDelete}>Borrar usuario</button>
                    </form> 
                }
            })}
            <button className='adminEditUsers__createUserButton' onClick={() => setCreateNewUser(!createNewUser)}>{!createNewUser ? 'Crear usuario' : 'Cancelar creacion'}</button>
            <button className='adminEditUsers__deleteUserButton' onClick={() => setConfirmDelete(!confirmDelete)}>{!confirmDelete ? "Quiero borrar un usuario" : 'Cancelar borrar un usuario'}</button>
            {createNewUser &&
                <form className='adminEditUsers__createUserForm' onSubmit={createUser}>
                    <input className="adminEditUsers__createUserFormMail" type="text" placeholder='Correo' name='mail' required/>
                    <input className='adminEditUsers__createUserFormPassword' type="text" placeholder='Contraseña' name='password' required />
                    <select className='adminEditUsers__createUserFormSelect' name="role" id="role" required>
                        <option value="">Seleccionar</option>
                        <option value="editor">Editor</option>
                        <option value="admin">Administrador</option>
                    </select>
                    <button className='adminEditUsers__createUserFormSubmit' type="submit">Crear Usuario</button>
                </form>
            }
        </div>
    )
}

export default AdminEditUsers