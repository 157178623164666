import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { SAVE_ZONES, SAVE_COMPANY } from '../utils/actions';
import { useAppState } from '../utils/AppContext';
import NewZones from './NewZones';
import axios from '../components/axios';

function AddCompany({ allDepartments, groupCodeData, allZones }) {
    const [{ saveZonesData }, dispatch] = useAppState();

    const [newCompanyclicks, setNewCompanyclicks] = useState([1]);
    const [newZonesClicks, setnewZonesClicks] = useState([]);
    const [newUbicationsClicks, setNewUbicationsClicks] = useState([]);
    const [companyName, setCompanyName] = useState("");
    const [saveCompanyData, setSaveCompanyData] = useState(false);
    const [finishedCompany, setFinishedCompany] = useState([]);
    const [departmentsAndZones, setDepartmentsAndZones] = useState({});
    const [disabled, setDisabled] = useState(true);

    const departmentsRef = useRef(null);
    const globalRef = useRef(null);

    const history = useHistory();

    useEffect(() => {
        dispatch({
            type: SAVE_COMPANY,
            saveCompanyInfo: false
        });
        setDepartmentsAndZones({ allAreas: allDepartments, allZones })
    }, [allDepartments, allZones, dispatch])
    
    const addNewZone = () => {
        const allZones = [...newZonesClicks];
        const lastNumberArray = allZones.pop() || 0;
        const lastNumber = lastNumberArray + 1;
        const newZones = [...newZonesClicks, lastNumber];
        setnewZonesClicks(newZones)
    }

    const eraseZone = (index) => {
        const newZone = [...newZonesClicks];
        const foundIndex = newZonesClicks.findIndex(element => element === index);
        newZone.splice(foundIndex, 1);
        setnewZonesClicks(newZone);
    }

    const addNewUbication = () => {
        const allUbications = [...newUbicationsClicks];
        const lastNumberArray = allUbications.pop() || 0;
        const lastNumber = lastNumberArray + 1;
        const newUbications = [...newUbicationsClicks, lastNumber];
        setNewUbicationsClicks(newUbications)
    }

    const eraseUbication = (index) => {
        const newUbications = [...newUbicationsClicks];
        const foundIndex = newUbicationsClicks.findIndex(element => element === index);
        newUbications.splice(foundIndex, 1);
        setNewUbicationsClicks(newUbications);
    }

    const saveCompany = async (e) => {
        e.preventDefault();
        const companyName = e.target.companyNameInput.value;
        const zone = newZonesClicks.length === 0 ? e.target.zoneInput.value : e.target.zoneInput[0].value;
        const firstUbication = e.target.ubicationInput[0] === undefined ? e.target.ubicationInput.value : e.target.ubicationInput[0].value;
        const firstDepartmentName = [];
        const firstDepartmentValue = [];
        const ubications = [];
        let zoneBlock;

        for(let i = 0; allDepartments.length > i; i++) {
            const index = i;
            const firstDepartment = globalRef.current.elements[`departmentsInput${index}`][0] === undefined ? globalRef.current.elements[`departmentsInput${index}`] : globalRef.current.elements[`departmentsInput${index}`][0];
            if(firstDepartment.checked) {
                firstDepartmentValue.push(firstDepartment.value * 1);
                firstDepartmentName.push(firstDepartment.getAttribute('data-name'));
            }
            if(i === allDepartments.length -1) {
                const newUbication = { ubicationName: firstUbication, departmentsNames: firstDepartmentName }
                ubications.push(newUbication);
            }
        }

        if(newUbicationsClicks.length > 0) {
            let departmentsValues = [];
            let departmentsNames = [];
            if(!newZonesClicks.length) {
                for(const ubication of newUbicationsClicks) {
                    const ubicationName = globalRef.current.elements[`ubicationInput${ubication}`].value;
                    for(let i = 0; allDepartments.length > i; i++) {
                        const index = i;
                        if(globalRef.current.elements[`departmentsInput${index}`][ubication].checked) {
                            departmentsValues.push(globalRef.current.elements[`departmentsInput${index}`][ubication].value * 1);
                            departmentsNames.push(globalRef.current.elements[`departmentsInput${index}`][ubication].getAttribute('data-name'));
                        }
                    }
                    const newUbication = { ubicationName, departmentsNames }
                    ubications.push(newUbication);
                    departmentsNames = [];
                    departmentsValues = [];
                }
            } else {
                for(const ubication of newUbicationsClicks) {
                    const ubicationName = globalRef.current.elements[`ubicationInput${ubication}`][0] === undefined ? globalRef.current.elements[`ubicationInput${ubication}`].value : globalRef.current.elements[`ubicationInput${ubication}`][0].value;
                    for(let i = 0; allDepartments.length > i; i++) {
                        const index = i;
                        if(globalRef.current.children[`ubicationInputBlock${ubication}`].children[`departmentsInputBlock${index}`].firstChild.children[`departmentsInput${index}`].checked) {
                            departmentsValues.push(globalRef.current.children[`ubicationInputBlock${ubication}`].children[`departmentsInputBlock${index}`].firstChild.children[`departmentsInput${index}`].value * 1);
                            departmentsNames.push(globalRef.current.children[`ubicationInputBlock${ubication}`].children[`departmentsInputBlock${index}`].firstChild.children[`departmentsInput${index}`].getAttribute('data-name'));
                        }
                    }
                    const newUbication = { ubicationName, departmentsNames }
                    ubications.push(newUbication);
                    departmentsNames = [];
                    departmentsValues = [];
                }
            }
        }

        zoneBlock = { zone, ubications }

        if(newZonesClicks.length !== 0) {
            await dispatch({
                type: SAVE_COMPANY,
                saveCompanyInfo: true
            })
        }
        await dispatch({
            type: SAVE_ZONES,
            newZone: zoneBlock
        })
        setCompanyName(companyName);
        setSaveCompanyData(true);
        setNewCompanyclicks([]);
        setnewZonesClicks([]);
        setDisabled(false);
    }

    useEffect(() => {
        if(saveCompanyData) {
            setFinishedCompany([...finishedCompany, { companyName, zoneBlock: saveZonesData }]);
            setCompanyName('');
            dispatch({
                type: SAVE_ZONES,
                newZone: []
            })
            setSaveCompanyData(false);
        }
    }, [saveCompanyData, companyName, dispatch, finishedCompany, saveZonesData])

    const addCompany = async (e) => {
        e.preventDefault();
        const groupData = groupCodeData;
        const newGroupData = {
            groupData,
            finishedCompany
        }
        try {
            const { data } = await axios.post('/api/addCompanyGroup', newGroupData);
            await dispatch({
                type: SAVE_COMPANY,
                saveCompanyInfo: false
            });
            alert(data);
            history.replace('/bhr-adminDashboard')
        } catch (error) {
            await dispatch({
                type: SAVE_COMPANY,
                saveCompanyInfo: false
            });
            alert(error.response.data.message);
            history.replace('/bhr-adminDashboard');
        }
    }

    return (
        <div className='addCompany__container'>
            {finishedCompany.map((company, index) => (
                <div key={index}>
                    <p><strong>Empresa:</strong> {company.companyName}</p>
                    {company.zoneBlock.map((zone, index) => (
                        <div key={index}>
                            <p><strong>Zona:</strong> {zone.zone}</p>
                            {zone.ubications.map((ubication, index) => (
                                <div key={index}>
                                    <p><strong>Ubicacion:</strong> {ubication.ubicationName}</p>
                                    {ubication.departmentsNames.map((department, index) => (
                                        <p key={index}><strong>Áreas:</strong> {department}</p>
                                    ))}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
            {newCompanyclicks.map((index) => (
                <form className='addCompany__form'  onSubmit={saveCompany} key={index} id={`globalForm${index}`} ref={globalRef}>
                    <label className='addCompany__formLabel' htmlFor="companyNameInput">Ingresa por favor el nombre de la empresa del grupo</label>
                    <input className='addCompany__formInput' type="text" name="companyNameInput" />
                    <label className='addCompany__formLabel' htmlFor="zoneInput">Ingresa por favor la zona de la empresa</label>
                    <select className='addCompany__formSelect' name="zoneInput" id="zoneInput">
                        <option value="">Seleccionar</option>
                        {departmentsAndZones.allZones?.map((zone, index) => (
                            <option key={index} value={zone.nombre}>{zone.nombre}</option>
                        ))}
                    </select>
                    <label className='addCompany__formLabel' htmlFor="ubicationInput">Ingresa por favor el nombre de la(s) ubicacion(es)</label>
                    <input className='addCompany__formInput' type="text" name="ubicationInput" />
                    <div className='addCompany__depsTitle'>Haz click en las áreas a dar de alta para las ubicaciones</div>
                    {allDepartments?.map((areas, index) => (
                        <div className='addCompany__depsContainer' key={index} ref={departmentsRef}>
                            <label><input className='addCompany__departments' type="checkbox" name={`departmentsInput${index}`} id={`departmentsInput${index}`} value={areas.areasOpciones_id} data-name={areas.nombre}/>{areas.nombre}</label>
                        </div>
                    ))}
                    {newUbicationsClicks.map((index) => (
                        <div className='addCompany__formNewUbicationContainer' key={index} id={`ubicationInputBlock${index}`}>
                            <input className='addCompany__formInput' type="text" name={`ubicationInput${index}`} />
                            {allDepartments?.map((areas, index) => (
                                <div className='addCompany__newUbicationDeps' key={index} id={`departmentsInputBlock${index}`}>
                                    <label className='addCompany__newUbicationDepsLabel'><input className='addCompany__newUbicationDepsInputs' type="checkbox" name={`departmentsInput${index}`} id={`departmentsInput${index}`} value={areas.areasOpciones_id} data-name={areas.nombre}/>{areas.nombre}</label>
                                </div>
                            ))}
                            <span className='addCompany__formNewUbicationDelete' onClick={() => eraseUbication(index)}>Borrar Ubicación</span>
                        </div>
                    ))}
                    <span className='addCompany__formAddUbication' onClick={addNewUbication}>Agregar otra Ubicación</span>
                    {newZonesClicks.map((index) => (
                        <NewZones key={index} companyOptions={departmentsAndZones} eraseZone={() => eraseZone(index)} />
                    ))}
                    <span className='addCompany__formAddZone' onClick={addNewZone}>Agregar otra zona para la misma empresa</span>
                    {/* <span className='addCompany__formDeleteCompany' onClick={eraseCompany}>Borrar Empresa</span> */}
                    <button className='addCompany__formSubmitCompany' type='submit'>Guardar Empresa</button>
                </form>
            ))}
            <button className='addCompany__addCompanyButton' onClick={addCompany} disabled={disabled}>Crear nueva empresa</button>
        </div>
    )
}

export default AddCompany