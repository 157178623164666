import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useAppState } from '../utils/AppContext';
import axios from '../components/axios';

function AdminDeleteComplaint() {
    const [{ userInfo }] = useAppState();

    const [error, setError] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if(!userInfo.status) {
            alert('Por favor ingresa con tu correo y contraseña');
            history.replace('/bhr-adminSignin');
        }

        return () => userInfo.status;

    }, [history, userInfo.status])
    
    const deleteComplaint = async (e) => {
        e.preventDefault();
        const complaintUUID = e.target.complaintUUID.value;
        if(complaintUUID === '') {
            setError(true);
            return
        } else {
            setError(false);
            try {
                const { data } = await axios.delete('/api/deleteComplaintData', {
                    data: {
                        complaintUUID
                    }
                });
                alert(data)
                history.replace('/bhr-adminDashboard');
            } catch (error) {
                alert(error.response.data.message);
                history.replace('/bhr-adminDashboard');
            }
        }
    }

    return (
        <div className='adminDeleteComplaint__container'>
            <form onSubmit={deleteComplaint} className="adminDeleteComplaint__form">
                <p className='adminDeleteComplaint__title'>Por favor escribe el UUID de la denuncia que quieras borrar</p>
                <input type="text" className='adminDeleteComplaint__input' name="complaintUUID" id="complaintUUID" required />
                <button className='adminDeleteComplaint__deleteButton'>Borrar Denuncia</button>
                {error ? <p className='adminDeleteComplaint__error'>Por favor escribe correctamente el UUID de la denuncia</p> : null}
            </form>
        </div>
    )
}

export default AdminDeleteComplaint