import React from 'react'
import { Link } from 'react-router-dom';
import { useAppState } from '../utils/AppContext';

function DenunciaId() {
    // eslint-disable-next-line
    const [{ complaintInfo }, dispatch] = useAppState();

    return (
        complaintInfo.length === 0 ? 
            <div>Por favor vaya a la página principal e introduzca su ID</div>
        : 
        (
            <div className='complaintIdContainer'>
                <h3>Seguimiento de la denuncia</h3>
                <div className='containerTags'>
                    <p>Guarda tu ID único para dar seguimiento a tu denuncia</p>
                    <div className='data'>{complaintInfo.internal_id}</div>
                </div>
                <div className="containerTags">
                    <p>Estatus de envio</p>
                    <div className='data'>{complaintInfo.estatusEnvio}</div>
                </div>
                <div className="containerTags">
                    <p>Estatus de revisión</p>
                    <div className='data'>{complaintInfo.estatusRevision}</div>
                </div>
                <div className="containerTags">
                    <p>Resultado de la revisión</p>
                    <div className='data'>{complaintInfo.resultadoRevision}</div>
                </div>
                <Link to={`/`} replace>Salir</Link>
                <footer className='denunciaId__footer'>
                    <p>En caso de requerir información puntual de la denuncia, por favor communicarse al 01 800 BHR México</p>
                </footer>
            </div>
        )
    )
}

export default DenunciaId
