import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useAppState } from '../utils/AppContext';
import axios from '../components/axios';

function AdminEditDepartments() {
    const [{ userInfo }] = useAppState();

    const [departments, setDepartments] = useState([]);
    const [createNewDepartment, setCreateNewDepartment] = useState(false);

    const history = useHistory();
    
    useEffect(() => {
        if(!userInfo.status) {
            alert('Por favor ingresa con tu correo y contraseña');
            history.replace('/bhr-adminSignin');
        } else {
            const fetchGroups = async () => {
                try {
                    const response = await axios.get('/api/fetchAllDepartments');
                    setDepartments(response.data);
                } catch (error) {
                    alert(error.response.data.message);
                    history.push('/bhr-adminDashboard');
                }
            }
            fetchGroups()
        }

        return () => userInfo.status;
    }, [history, userInfo.status])

    const createDepartment = async (e) => {
        e.preventDefault();
        try {
            const newDepartment = e.target.newDepartmentName.value;
            const { data } = await axios.post('/api/createNewDepartment', {
                newDepartment
            });
            alert(data);
            history.replace('/bhr-adminDashboard');
        } catch (error) {
            alert(error.response.data.message);
            history.push('/bhr-adminDashboard');
        }
    }

    return (
        <div className='adminEditDep__container'>
            <div className='adminEditDep__depsContainer'>
                {departments?.map((dep, index) => (
                    <p className='adminEditDep__depNames' key={index}>{dep.nombre}</p>
                ))}
            </div>
            <button className='adminEditDep__depButton' onClick={() => setCreateNewDepartment(!createNewDepartment)}>{!createNewDepartment ? "Crear un nuevo departamento" : "Cancelar nuevo departamento"}</button>
            {createNewDepartment &&
                <form className='adminEditDep__form' onSubmit={createDepartment}>
                    <input className='adminEditDep__formInput' type="text" name='newDepartmentName' required />
                    <button className='adminEditDep__formButton'>Crear nuevo departamento</button>
                </form>
            }
        </div>
    )
}

export default AdminEditDepartments