import { COMPANIES_NAMES, COMPLAINT_INFO, USER_INFO, SAVE_COMPANY, SAVE_ZONES } from './actions'

export const initialState = {
    companies: [],
    complaintInfo: [],
    userInfo: {},
    saveCompanyInfo: false,
    saveZonesData: [],
}

const reducer = (state, action) => {
    switch(action.type){
        case COMPANIES_NAMES:
            return {
                ...state,
                companies: action.companies
            }
        case COMPLAINT_INFO:
            return {
                ...state,
                complaintInfo: action.complaintInfo
            }
        case USER_INFO:
            return {
                ...state,
                userInfo: action.userInfo
            }
        case SAVE_COMPANY:
            return {
                ...state,
                saveCompanyInfo: action.saveCompanyInfo
            }
        case SAVE_ZONES:
            const zoneData = action.newZone;
            if(zoneData.length === undefined) {
                return {
                    ...state,
                    saveZonesData: [...state.saveZonesData, action.newZone]
                }
            } else {
                return {
                    ...state,
                    saveZonesData: []
                }
            }
        default:
            return state;
    }
}

export default reducer
