import axios from './axios';
import React, { useEffect, useState } from 'react'
import NewZones from './NewZones';
import { SAVE_ZONES, SAVE_COMPANY } from '../utils/actions';
import { useAppState } from '../utils/AppContext';
import { useHistory } from 'react-router-dom';

function EditCompanyFields({ data, allDepartments, groupCodeData }) {
    const [{ saveZonesData }, dispatch] = useAppState();

    const [disabled, setDisabled] = useState(true);
    const [editDisabled, setEditDisabled] = useState(false);
    const [newZoneDisabled, setNewZoneDisabled] = useState(false);
    const [confirmNewZone, setConfirmNewZone] = useState(false);
    const [inputsFields, setInputsFields] = useState({ companyName: '', zoneData: '', ubication: ''})
    const [newZonesClicks, setNewZonesClicks] = useState([]);
    const [departments, setDepartments] = useState({});
    const [updateData, setUpdateData] = useState(false);
    const [saveCompanyData, setSaveCompanyData] = useState(false);
    const [finishedCompany, setFinishedCompany] = useState([]);
    const [disableZoneButtonDB, setDisableZoneButtonDB] = useState(true);
    const [zoneSaveDisabled, setZoneSaveDisabled] = useState(false);
    const [addAnotherZoneDisabled, setAddAnotherZoneDisabled] = useState(false);
    const [zonesOptions] = useState(data.zonesOptions)
    
    const history = useHistory();

    useEffect(() => {
        dispatch({
            type: SAVE_COMPANY,
            saveCompanyInfo: false
        });
        setDepartments({ allAreas: allDepartments, allZones: zonesOptions })
    }, [allDepartments, zonesOptions, dispatch])
    
    const editCompamy = () => {
        if(disabled) {
            setDisabled(false);
            setNewZoneDisabled(true);
        } else {
            setDisabled(true);
            setNewZoneDisabled(false);
        }
    }

    const editNewZone = () => {
        if(!confirmNewZone) {
            setDisabled(true);
            setEditDisabled(true);
            setConfirmNewZone(true);
            addNewZone()
            setAddAnotherZoneDisabled(false)
            setDisableZoneButtonDB(true)
        } else {
            setEditDisabled(false);
            setConfirmNewZone(false);
            eraseAllZones()
            setFinishedCompany([]);
            dispatch({
                type: SAVE_COMPANY,
                saveCompanyInfo: false
            });
        }
    }

    const addNewZone = () => {
        const allZones = [...newZonesClicks];
        const lastNumberArray = allZones.pop() || 0;
        const lastNumber = lastNumberArray + 1;
        const newZones = [...newZonesClicks, lastNumber];
        setNewZonesClicks(newZones)
        setZoneSaveDisabled(false);
    }

    const eraseAllZones = () => {
        const allZones = [...newZonesClicks];
        allZones.length = 0;
        setNewZonesClicks(allZones);
    }

    const eraseZone = (index) => {
        const newZone = [...newZonesClicks];
        const foundIndex = newZonesClicks.findIndex(element => element === index);
        newZone.splice(foundIndex, 1);
        setNewZonesClicks(newZone);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputsFields({
            ...inputsFields,
            [name]: value
        });
    }
    
    const addZones = async () => {
        if(newZonesClicks.length !== 0) {
            await dispatch({
                type: SAVE_COMPANY,
                saveCompanyInfo: true
            })
        }
        setSaveCompanyData(true);
        setNewZonesClicks([]);
        setDisableZoneButtonDB(false);
        setZoneSaveDisabled(true);
        setAddAnotherZoneDisabled(true)
    }

    useEffect(() => {
        if(saveCompanyData) {
            setFinishedCompany([...finishedCompany, { companyId: data.companyInfo.id, zoneBlock: saveZonesData }]);
            dispatch({
                type: SAVE_ZONES,
                newZone: []
            })
            setSaveCompanyData(false);
        }
    }, [saveCompanyData, dispatch, data.companyInfo.id, finishedCompany, saveZonesData])
    
    // Funcion para actualizar la info de nombre y ubicaciones. Ya esta lista 
    const updateCompanyInfo = async (e) => {
        e.preventDefault()
        const companyInfo = {
            companyName: e.target.children.companyName.value,
            companyId: e.target.children.companyName.getAttribute('data-id'),
            groupCode: groupCodeData,
        }
        const valuesToUpdate = [];
        const departmentsToUpdate = [];
        for(let i = 0; i < data.zoneBlock.length; i++) {
            const ubicationInput = e.target.children[`zoneBlock${i}`].children.ubication.value;
            const ubicationId = e.target.children[`zoneBlock${i}`].children.ubication.getAttribute('data-id');
            const zoneInput = e.target.children[`zoneBlock${i}`].children.zoneSelection.getAttribute('data-id');
            const zoneName = e.target.children[`zoneBlock${i}`].children.zoneSelection.value;
            console.log(zoneName)
            for(let k = 0; allDepartments.length > k; k++) {
                const index = k;
                if(e.target.children[`zoneBlock${i}`].children[`departmentsLabel${index}`].children[`department${index}`].checked) {
                    const name = e.target.children[`zoneBlock${i}`].children[`departmentsLabel${index}`].children[`department${index}`].value;
                    const ubicationId = e.target.children[`zoneBlock${i}`].children[`departmentsLabel${index}`].children[`department${index}`].getAttribute('data-ubicationid') * 1;
                    departmentsToUpdate.push({
                        name,
                        ubicationId
                    })
                }
            }
            valuesToUpdate.push({
                zoneBlock: {
                    ubication: {
                        name: ubicationInput,
                        ubicacion_id: ubicationId
                    },
                    zoneData: {
                        zona_id: zoneInput,
                        zoneName
                    }
                }
            })
        }

        try {
            const { data } = await axios.put('/api/updateCompanyInfo', {
                companyInfo,
                valuesToUpdate,
                departmentsToUpdate
            });
            await dispatch({
                type: SAVE_COMPANY,
                saveCompanyInfo: false
            });
            alert(data);
            history.replace('/bhr-adminDashboard');
        } catch (error) {
            await dispatch({
                type: SAVE_COMPANY,
                saveCompanyInfo: false
            });
            alert(error.response.data.message);
            history.replace('/bhr-adminDashboard');
        }
    }

    const addZonesToDB = async () => {
        const company = finishedCompany.pop();
        try {
            const { data } = await axios.post('/api/addNewZonesToCompany', {
                company
            });
            await dispatch({
                type: SAVE_COMPANY,
                saveCompanyInfo: false
            })
            alert(data);
            setConfirmNewZone(false);
            setFinishedCompany([]);
            history.replace('/bhr-adminDashboard');
        } catch (error) {
            await dispatch({
                type: SAVE_COMPANY,
                saveCompanyInfo: false
            });
            alert(error.response.data.message);
            history.replace('/bhr-adminDashboard');
        }
    }

    const deleteZone = async (e) => {
        const zoneValue = e.target.parentElement.children.zoneSelection.value;
        const ubicationValue = e.target.parentElement.children.ubication.getAttribute('data-id');
        const companyId = data.companyInfo.id;
        try {
            const { data } = await axios.delete(`/api/deleteZone`, {
                data: {
                    zoneValue,
                    ubicationValue,
                    companyId
                }
            });
            alert(data);
            history.replace('/bhr-adminDashboard');
        } catch (error) {
            alert(error.response.data.message);
            history.replace('/bhr-adminDashboard');
        }
    }

    const deleteCompany = async (e) => {
        e.preventDefault();
        const companyId = e.target.parentElement.children.companyName.getAttribute('data-id');
        try {
            const { data } = await axios.delete('/api/deleteCompany', {
                data: {
                    companyId
                }
            });
            alert(data);
            history.replace('/bhr-adminDashboard');
        } catch (error) {
            alert(error.response.data.message);
            history.replace('/bhr-adminDashboard');
        }
    }

    return (
        <div className='editCompanyFields__container'>
            <form onSubmit={updateCompanyInfo} className="editCompanyFields__form">
                <input className='editCompanyFields__formCompanyName' type="text" name='companyName' defaultValue={data.companyInfo.name} onChange={handleChange} disabled={disabled} data-id={data.companyInfo.id} />
                {data.zoneBlock.map((zone, index) => (
                    <div className='editCompanyFields__formZoneBlock' key={index} name={`zoneBlock${index}`}>
                        <select className='editCompanyFields__formZoneSelection' name="zoneSelection" id="zoneSelection" onChange={handleChange} disabled={disabled} data-id={zone.zoneData.zona_id}>
                            <option value={zone.zoneData.nombre}>{zone.zoneData.nombre}</option>
                            {zonesOptions.map((zoneOption, index) => {
                                if(zoneOption.nombre === zone.zoneData.nombre) {
                                    return null
                                } else {
                                    return <option key={index} value={zoneOption.nombre}>{zoneOption.nombre}</option>
                                }
                            })}
                        </select>
                        <input className='editCompanyFields__formUbicationName' type="text" name='ubication' onChange={handleChange} defaultValue={zone.ubications.ubicationData.ubicacion} disabled={disabled} data-id={zone.ubications.ubicationData.ubicacion_id} />
                        {allDepartments.map((department, index) => (
                            <label className='editCompanyFields__formDepartments' key={index} name={`departmentsLabel${index}`}>
                                <input type="checkbox" name={`department${index}`} id={`department${index}`} disabled={disabled}
                                value={department.nombre}
                                data-ubicationid={zone.ubications.ubicationData.ubicacion_id}
                                defaultChecked={zone.ubications.departmentsNames.includes(department.nombre)}
                            />
                                {department.nombre}
                            </label>
                        ))}
                        <input className='editCompanyFields__deleteRegistry' type="button" value="Borrar registro" onClick={deleteZone} disabled={disabled}/>
                    </div>
                ))}
                <input className='editCompanyFields__deleteCompany' type="button" value="Borrar empresa" onClick={deleteCompany} disabled={!updateData || disabled} />
                <button className='editCompanyFields__updateCompany' type='submit' disabled={!updateData || disabled}>Actualizar datos</button>
            </form>
            <div className='editCompanyFields__editButtons'>
                <button className='editCompanyFields__editButtonsEdit' onClick={editCompamy} disabled={editDisabled}>{disabled ? "Editar empresa" : "Acabar edición"}</button>
                <button className='editCompanyFields__editButtonsZone' onClick={editNewZone} disabled={newZoneDisabled}>{!confirmNewZone ? 'Agregar una zona nueva' : 'Cancelar nueva zona'}</button>
                <button className='editCompanyFields__editButtonsUpdate' onClick={() => setUpdateData(!updateData)} disabled={disabled}>{!updateData || disabled ? 'Quiero actualizar o borrar los datos de la compañía' : 'Cancelar actualización o eliminación de datos'}</button>
            </div>
            {confirmNewZone &&
                <> 
                    {finishedCompany.length > 0 && finishedCompany[0].zoneBlock.map((zoneInfo, index) => (
                        <div key={index}>
                            <p><strong>Zona:</strong> {zoneInfo.zone}</p>
                            {zoneInfo.ubications.map((ubication, index) => (
                                <div key={index}>
                                    <p><strong>Ubicación:</strong> {ubication.ubicationName}</p>
                                    {ubication.departmentsNames.map((dep, index) => (
                                        <p key={index}><strong>Área:</strong> {dep}</p>
                                    ))}
                                </div>
                            ))}
                        </div>
                    ))}
                    {newZonesClicks.map((index) => (
                        <NewZones key={index} companyOptions={departments} eraseZone={() => eraseZone(index)} />
                    ))}
                    <button className='editCompanyFields__newZone' onClick={addNewZone} disabled={addAnotherZoneDisabled}>Agregar otra zona para la misma empresa</button>
                    <button className='editCompanyFields__newZoneSave' onClick={addZones} disabled={zoneSaveDisabled || newZonesClicks.length === 0}>Guardar Zona</button>
                    <button className='editCompanyFields__newZoneAdd' onClick={addZonesToDB} disabled={disableZoneButtonDB}>Agregar zona(s) a la base de datos</button>
                </>
            }
        </div>
    )
}

export default EditCompanyFields