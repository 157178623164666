import React, { useEffect, useRef, useState } from 'react'
import axios from '../components/axios';
import { useAppState } from '../utils/AppContext';
import { Link } from 'react-router-dom';
import { SAVE_COMPANY } from '../utils/actions';
import excelIcon from '../assets/excelIcon.png';
import * as XLSX from 'xlsx';

function AdminDashboard() {
    // eslint-disable-next-line
    const [{ userInfo }, dispatch] = useAppState();
    const [complaintInfo, setComplaintInfo] = useState(null);

    const complaintCheckedRef = useRef(null);
    const complaintResultRef = useRef(null);

    useEffect(() => {
        dispatch({
            type: SAVE_COMPANY,
            saveCompanyInfo: false
        })
    }, [dispatch])
    
    
    const searchComplaint = async (e) => {
        e.preventDefault();
        const internal_id = e.target.complaintUUID.value;
        try {
            const { data } = await axios.post('/api/searchComplaintData', {
                internal_id
            });
            setComplaintInfo(data);
            complaintCheckedRef.current.value = data.estatusRevision;
            complaintResultRef.current.value = data.resultadoRevision;
        } catch (error) {
            alert("Por favor ingresa correctamente el ID de la denuncia")
        }
    }

    const downloadExcel = async (e) => {
        e.preventDefault();
        try {
            const { data } = await axios.get('/api/complaintExcel');
            for(const info of data) {
                delete info.uri;
                delete info.denuncia_id;
                delete info.involucrados;
                delete info.fotos;
            }
            const file = "Denuncias.xlsx";
            const myWorkSheet = XLSX.utils.json_to_sheet(data);
            const myworkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(myworkBook, myWorkSheet, "Denuncias");
            XLSX.writeFile(myworkBook, file)
        } catch (error) {
            alert(error.response.data.message)
        }
    }

    const updateHanlder = async (e) => {
        e.preventDefault();
        const status = e.target.value;
        const handler = e.target.id;
        const id = complaintInfo.denuncia_id
        try {
            await axios.put('/api/updateComplaintStatus', {
                status,
                handler,
                id
            });
            alert("Estatus cambiado con éxito")
        } catch (error) {
            if(handler === 'complaintChecked') {
                complaintCheckedRef.current.value = complaintInfo.estatusRevision
            } else if(handler === 'complaintResult') {
                complaintResultRef.current.value = complaintInfo.resultadoRevision
            }
            alert('Por favor intentalo de nuevo')
        }
    }

    return !userInfo.status ? (
        <Link to="/bhr-adminSignin">Por favor registrate para continuar</Link>
    ) : (
        <div className='dashboardContainer'>
            <div className="dasboardContainer__excelDownload">
                <img src={excelIcon} onClick={downloadExcel} alt="excel-icon" height={30} width={30} />
                <p>Descargar las denuncias en Excel</p>
            </div>
            <h3 className='dashboardTitle'>Ingresa el ID de la denuncia para ver el seguimiento</h3>
            <form onSubmit={searchComplaint} className="dashboardForm">
                <input type="text" name='complaintUUID' required />
                <button>Buscar</button>
            </form>
            {userInfo.tipo === 'admin' && (
                <div className='dashboardAdminSettings'>
                    <Link to="/bhr-adminDashboard/newCompany">Agregar un nuevo grupo</Link>
                    <Link to="/bhr-adminDashboard/editCompany">Editar un grupo</Link>
                    <Link to="/bhr-adminDashboard/deleteCompany">Borrar un grupo</Link>
                    <Link to="/bhr-adminDashboard/editZones">Agregar zonas</Link>
                    <Link to="/bhr-adminDashboard/editDepartments">Agregar áreas</Link>
                    <Link to="/bhr-adminDashboard/editUsers">Editar usuarios</Link>
                    <Link to="/bhr-adminDashboard/deleteComplaint">Borrar Denuncias</Link>
                </div>
            )}
            {complaintInfo &&
                <div className='dashboardComplaintInfo'>
                    <div className='complaintData'>
                        <h4>Empresa</h4><p>{complaintInfo.empresa}</p>
                    </div>
                    <div className='complaintData'>
                        <h4>Denunciante </h4><p>{complaintInfo.denunciante}</p>
                    </div>
                    <div className='complaintData'>
                        <h4>Zona </h4><p>{complaintInfo.zona}</p>
                    </div>
                    <div className='complaintData'>
                        <h4>Ubicacion </h4><p>{complaintInfo.ubicacion}</p>
                    </div>
                    <div className='complaintData'>
                        <h4>Area </h4><p>{complaintInfo.area}</p>
                    </div>
                    <div className='complaintData'>
                        <h4>Tipo de denuncia </h4><p>{complaintInfo.denuncia}</p>
                    </div>
                    <div className='complaintData'>
                        <h4>Detalle de la denuncia </h4><p>{complaintInfo.denunciaDetalle}</p>
                    </div>
                    <h4>Involucrados:</h4>
                    {complaintInfo.involucrados.map((involucrado, index) => (
                        <div key={index}>
                            <div className='complaintData'>
                                <h4>Nombre del involucrado </h4><p>{involucrado.nameInvolved}</p>
                            </div>
                            <div className='complaintData'>
                                <h4>Apellido del involucrado </h4><p>{involucrado.lastNameInvolved}</p>
                            </div>
                            <div className='complaintData'>
                                <h4>Puesto del involucrados </h4><p>{involucrado.positionInvolved}</p>
                            </div>
                            <div className='complaintData'>
                                <h4>El involucrado es </h4><p>{involucrado.involvedInfractor ? 'Infractor' : 'Testigo'}</p>
                            </div>
                        </div>
                    ))}
                    <div className='complaintData'>
                        <h4>Situación </h4><p>{complaintInfo.situacion}</p>
                    </div>
                    <h4>Fotos:</h4>
                    {complaintInfo.fotos.map((fotos, index) => (
                        <div key={index} className="complaintData">
                            <h4>Liga {index + 1}</h4><p>https://coral-app-tn3j6.ondigitalocean.app/{fotos.path}</p>
                        </div>
                    ))}
                    <div className='complaintData'>
                        <h4>Nombre del testigo </h4><p>{complaintInfo.testigoNombre !== '' ? complaintInfo.testigoNombre : 'Anónimo'}</p>
                    </div>
                    <div className='complaintData'>
                        <h4>Apellido del testigo </h4><p>{complaintInfo.testigoApellido !== '' ? complaintInfo.testigoApellido : 'Anónimo'}</p>
                    </div>
                    <div className='complaintData'>
                        <h4>Puesto del testigo </h4><p>{complaintInfo.testigoPuesto !== '' ? complaintInfo.testigoPuesto : 'Anónimo'}</p>
                    </div>
                    <div className='complaintData'>
                        <h4>Teléfono del testigo </h4><p>{complaintInfo.testigoTelefono !== '' ? complaintInfo.testigoTelefono : 'Anónimo'}</p>
                    </div>
                    <div className='complaintData'>
                        <h4>Correo del testigo </h4><p>{complaintInfo.testigoCorreo !== '' ? complaintInfo.testigoCorreo : 'Anónimo'}</p>
                    </div>
                    <div className='complaintData'>
                        <h4>El testigo es afectado directo </h4><p>{complaintInfo.testigoAfectado !== '' ? complaintInfo.testigoAfectado : 'Anónimo'}</p>
                    </div>
                    <div className='complaintData'>
                        <h4>El testigo ya habia hecho uso de la linea de denuncia </h4><p>{complaintInfo.usoDenuncia === 'si' ? "Si" : 'No'}</p>
                    </div>
                    <div className='complaintData'>
                        <h4>Medio por el que se entero de la linea de denuncia </h4><p>{complaintInfo.medio}</p>
                    </div>
                    <div className='complaintData'>
                        <h4>Estatus de envío </h4><p>{complaintInfo.estatusEnvio}</p>
                    </div>
                    <div className='complaintStatus'>
                        <div className='statusSelections'>
                            <label htmlFor="complaintChecked">Estatus de Revisión</label>
                            <select name="complaintChecked" id="complaintChecked" onChange={updateHanlder} ref={complaintCheckedRef}>
                                <option value="Pendiente de revisión">Pendiente de revisión</option>
                                <option value="En proceso de revisión">En proceso de revisión</option>
                                <option value="Revisión concluida">Revisión concluida</option>
                            </select>
                        </div>
                        <div className="statusSelections">
                            <label htmlFor="complaintResult">Estatus de Resultado</label>
                            <select name="complaintResult" id="complaintResult" onChange={updateHanlder} ref={complaintResultRef}>
                                <option value="Pendiente de revisión">Pendiente de revisión</option>
                                <option value="En revisión">En revisión</option>
                                <option value="Procedente para investigación">Procedente para investigación</option>
                                <option value="No procedente">No procedente</option>
                                <option value="No procedente por falta de información">No procedente por falta de información</option>
                            </select>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default AdminDashboard
