import React, { useEffect, useRef, useState } from 'react';
import { SAVE_ZONES } from '../utils/actions';
import { useAppState } from '../utils/AppContext';

function NewZones({ companyOptions, eraseZone }) {
    const [{ saveCompanyInfo }, dispatch] = useAppState();

    const [newUbicationsClicks, setNewUbicationsClicks] = useState([]);

    const newZoneRef = useRef(null);

    useEffect(() => {
        if(saveCompanyInfo) {
            const zone = newZoneRef.current.children.zoneInput.value;
            const firstUbication = newZoneRef.current.children.ubicationInput[0] === undefined ? newZoneRef.current.children.ubicationInput.value : newZoneRef.current.children.ubicationInput[0].value;
            const firstDepartmentName = [];
            const firstDepartmentValue = [];
            const ubications = [];

            for(let i = 0; companyOptions.allAreas.length > i; i++) {
                const index = i;
                const firstDepartment = newZoneRef.current.children[`departmentsInput${index}`][0] === undefined ? newZoneRef.current.children[`departmentsInput${index}`].firstChild.children[`departmentsInput${index}`] : newZoneRef.current.children[`departmentsInput${index}`][0].firstChild.children[`departmentsInput${index}`][0];
                if(firstDepartment.checked) {
                    firstDepartmentValue.push(firstDepartment.value * 1);
                    firstDepartmentName.push(firstDepartment.getAttribute('data-name'));
                }
                if(i === companyOptions.allAreas.length -1) {
                    const newUbication = { ubicationName: firstUbication, departmentsNames: firstDepartmentName }
                    ubications.push(newUbication);
                }
            }

            if(newUbicationsClicks.length > 0) {
                for(const ubication of newUbicationsClicks) {
                    let departmentsNames = [];
                    let departmentsValues = [];
                    const ubicationName = newZoneRef.current.children[`ubicationInput${ubication}`].firstChild.value;
                    for(let i = 0; companyOptions.allAreas.length > i; i++) {
                        const index = i;
                        if(newZoneRef.current.children[`ubicationInput${ubication}`].children[`departmentsInput${index}`].firstChild.children[`departmentsInput${index}`].checked) {
                            departmentsValues.push(newZoneRef.current.children[`ubicationInput${ubication}`].children[`departmentsInput${index}`].firstChild.children[`departmentsInput${index}`].value * 1);
                            departmentsNames.push(newZoneRef.current.children[`ubicationInput${ubication}`].children[`departmentsInput${index}`].firstChild.children[`departmentsInput${index}`].getAttribute('data-name'));
                        }
                    }
                    const newUbication = { ubicationName, departmentsNames }
                    ubications.push(newUbication);
                    departmentsNames = [];
                    departmentsValues = [];
                }
            }
            
            const newZonesData = {
                zone,
                ubications
            }
            
            dispatch({
                type: SAVE_ZONES,
                newZone: newZonesData
            })
        }
    }, [saveCompanyInfo, companyOptions.allAreas, newUbicationsClicks, dispatch])
    

    const addNewUbication = () => {
        const allUbications = [...newUbicationsClicks];
        const lastNumberArray = allUbications.pop() || 0;
        const lastNumber = lastNumberArray + 1;
        const newUbications = [...newUbicationsClicks, lastNumber];
        setNewUbicationsClicks(newUbications);
    }

    const eraseUbication = (index) => {
        const newUbications = [...newUbicationsClicks];
        const foundIndex = newUbicationsClicks.findIndex(element => element === index);
        newUbications.splice(foundIndex, 1);
        setNewUbicationsClicks(newUbications);
    }
    
    return (
        <div className='newZonesContainer' ref={newZoneRef}>
            <label htmlFor={`zoneInput`}>Ingresa por favor la zona de la empresa</label>
            <select className='newZoneSelect' name={`zoneInput`} id={`zoneInput`}>
                <option value="">Seleccionar</option>
                {companyOptions.allZones?.map((zones, index) => (
                    <option key={index} value={zones.nombre}>{zones.nombre}</option>
                ))}
            </select>
            <label htmlFor={`ubicationInput`}>Ingresa por favor el nombre de la ubicación</label>
            <input className='newZoneUbicationInput' type="text" name={`ubicationInput`} placeholder="Ubicación" />
            <div className='newZone__depTitle'>Haz click en las áreas a dar de alta para esta ubicación</div>
            {companyOptions.allAreas?.map((areas, index) => (
                <div className='newZone__depContainer' key={index} id={`departmentsInput${index}`}>
                    <label><input type="checkbox" name={`departmentsInput${index}`} id={`departmentsInput${index}`} value={areas.areasOpciones_id} data-name={areas.nombre}/>{areas.nombre}</label>
                </div>
            ))}
            {newUbicationsClicks.map((index) => (
                <div className='newZone__newUbicationContainer' key={index} id={`ubicationInput${index}`}>
                    <input className='newZone__newUbicationInput' type="text" name={`ubicationInput${index}`} />
                    {companyOptions.allAreas?.map((areas, index) => (
                        <div className='newZone__depContainer' key={index} id={`departmentsInput${index}`}>
                            <label className='newZone__depLabel'><input type="checkbox" name={`departmentsInput${index}`} id={`departmentsInput${index}`} value={areas.areasOpciones_id} data-name={areas.nombre}/>{areas.nombre}</label>
                        </div>
                    ))}
                    <span className='newZone__deleteZoneButton' onClick={() => eraseUbication(index)}>Borrar ubicación</span>
                </div>
            ))}
            <span className='newZone__addUbication' onClick={addNewUbication}>Agregar otra ubicación</span>
            <span className='newZone__deleteUbication' onClick={eraseZone}>Borrar zona</span>
        </div>
    );
}

export default NewZones;
