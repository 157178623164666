import React, { useEffect, useRef, useState } from 'react'
import axios from '../components/axios';
import { useHistory, useParams } from 'react-router-dom';
import { useAppState } from '../utils/AppContext';
import { COMPANIES_NAMES, COMPLAINT_INFO } from '../utils/actions';
import megaphone from '../assets/megaphone.png'

function Denuncia() {
    const [{ companies }, dispatch] = useAppState();
    const { companyId } = useParams();

    const [clasificacionDetail, setClasificacionDetail] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [complaintResponse, setComplaintResponse] = useState(null);
    const [companyZone, setCompanyZone] = useState([]);
    const [companyUbication, setCompanyUbication] = useState([]);
    const [companyDepartments, setCompanyDepartments] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const [clicks, setClicks] = useState([]);
    const [newPics, setNewPics] = useState([]);
    const [allInvolved, setAllInvolved] = useState([]);
    const [allPictures, setAllPictures] = useState([]);

    const companyRef = useRef(null);
    const zoneRef = useRef(null);
    const ubicationRef = useRef(null);
    const departmentRef = useRef(null);
    const involvedRef = useRef(null);
    const involvedPhoto = useRef(null);

    const history = useHistory();

    useEffect(() => {
        // If context state === undefined then do the fetch to the api again with the companyId param and setCompanyInfo to the response, else just set the companyInfo to the info that's already in the state
        // I'm doing this in case someone enters directly in the url with the companyId param and not from the main page
        const allCompanies = [];
        if(companies.length === 0){
            const id = companyId;
            const fetchCompany = async () => {
                try {
                    const { data } = await axios.post('/api/getCompanyId', {
                        companyId: id
                    });
                    for(let info of data.foundCompanies){
                        const companyData = {
                            name: info.nombre,
                            id: info.empresa_id
                        }
                        allCompanies.push(companyData);
                    }
                    dispatch({
                        type: COMPANIES_NAMES,
                        companies: allCompanies
                    });
                } catch (error) {
                    alert('ID incorrecto, porfavor ingresa el ID de tu empresa');
                    history.replace('/');
                }
            }
            fetchCompany();
        }
        return () => companies;
    }, [companies, companyId, dispatch, history])
    
    useEffect(() => {
        const fetchComplaintInfo = async () => {
            try {
                const response = await axios.get('/api/complaintFormFields');
                setComplaintResponse(response.data);
            } catch (error) {
                alert(error)
            }
        }
        fetchComplaintInfo();
    }, [])

    useEffect(() => {
        zoneRef.current.value = '';
        ubicationRef.current.value = '';
        departmentRef.current.value = '';
        setCompanyUbication(null);
        setCompanyDepartments(null);
    }, [companyRef.current?.value])

    useEffect(() => {
        ubicationRef.current.value = '';
        departmentRef.current.value = '';
        setCompanyDepartments(null);
    }, [zoneRef.current?.value])

    useEffect(() => {
        departmentRef.current.value = '';
    }, [ubicationRef.current?.value])

    const sendForm = async (e) => {
        e.preventDefault();
        setDisableSubmit(true);
        let allPeople = [{
            nameInvolved: e.target.nameInvolved.value,
            lastNameInvolved: e.target.lastNameInvolved.value,
            positionInvolved: e.target.positionInvolved.value,
            involvedInfractor: e.target.involvedInfractor.checked,
            involvedWitness: e.target.involvedTestigo.checked
        }];
        let allNewPics = [e.target.fileImage.files[0]]
        let formData = new FormData();
        let denunciante = e.target.employee.value;
        let company = e.target.company.value;
        let zone = e.target.zone.value;
        let ubication = e.target.ubication.value;
        let area = e.target.area.value;
        let complaintType1 = e.target.type[0].value;
        let complaintType2 = e.target.type[1].value;
        let situation = e.target.textDetails.value;
        let witnessName = e.target.witnessName.value;
        let witnessLastName = e.target.witnessLastName.value;
        let witnessPosition = e.target.witnessPosition.value;
        let witnessNumber = e.target.witnessNumber.value;
        let witnessMail = e.target.witnessMail.value;
        let witnessAffected = e.target.witnessAffected.value;
        let additionalQuestion1 = e.target.additionalQuestion1.value;
        let additionalQuestion2 = e.target.additionalQuestion2.value;

        if(clicks.length > 0) {
            for(const click of clicks) {
                let nameInvolved = involvedRef.current.parentElement.parentElement.parentElement[`nameInvolved${click}`].value;
                let lastNameInvolved = involvedRef.current.parentElement.parentElement.parentElement[`lastNameInvolved${click}`].value;
                let positionInvolved = involvedRef.current.parentElement.parentElement.parentElement[`positionInvolved${click}`].value;
                let involvedInfractor = involvedRef.current.parentElement.parentElement.parentElement[`involvedInfractor${click}`].checked;
                let involvedWitness = involvedRef.current.parentElement.parentElement.parentElement[`involvedTestigo${click}`].checked;
                let newInvolvedPeople = {
                    nameInvolved,
                    lastNameInvolved,
                    positionInvolved,
                    involvedInfractor,
                    involvedWitness
                }
                allPeople.push(newInvolvedPeople);
            }
        }

        if(newPics.length > 0) {
            for(const newPic of newPics) {
                let newInvolvedPic = involvedPhoto.current.form.elements[`fileImage${newPic}`].files[0];
                allNewPics.push(newInvolvedPic);
            }
        }

        formData.append("denunciante", denunciante)
        formData.append("company", company);
        formData.append("zone", zone);
        formData.append("ubication", ubication);
        formData.append("area", area);
        formData.append("complaintType1", complaintType1);
        formData.append("complaintType2", complaintType2);
        formData.append("involvedPeople", JSON.stringify(allPeople));
        formData.append("situation", situation);
        formData.append("witnessName", witnessName);
        formData.append("witnessLastName", witnessLastName);
        formData.append("witnessPosition", witnessPosition);
        formData.append("witnessNumber", witnessNumber);
        formData.append("witnessMail", witnessMail);
        formData.append("witnessAffected", witnessAffected);
        formData.append("additionalQuestion1", additionalQuestion1);
        formData.append("additionalQuestion2", additionalQuestion2);

        for(const pics of allNewPics) {
            formData.append("pictures", pics);
        }

        try {
            const { data } = await axios({
                method: 'post',
                url: '/api/sendComplaint',
                data: formData,
                headers: { 'content-type': 'multipart/form-data' }
            });
            dispatch({
                type: COMPLAINT_INFO,
                complaintInfo: data
            });
            alert("Información subida con éxito");
            history.replace(`/denuncia/${data.uri}`)
        } catch (error) {
            alert("Hubo un error, por favor intentalo de nuevo");
            setDisableSubmit(false);
        }
    }

    const fetchCompanyZone = async (e) => {
        const selectedCompanyId = e.target.value;
        if(selectedCompanyId !== ''){
            try {
                const { data } = await axios.post('/api/getCompanyZone', {
                    selectedCompanyId
                });
                setCompanyZone(data)
            } catch (error) {
                alert(error);
            }
        } else {
            setCompanyZone([]);
        }
    }

    const fetchUbication = async () => {
        const companyId = companyRef.current.value;
        const zoneId = zoneRef.current.value;
        if(companyRef === '' && zoneRef === ''){
            setCompanyUbication([]);
            return
        } else {
            try {
                const { data } = await axios.post('/api/fetchUbication', {
                    companyId: companyId * 1,
                    zoneId: zoneId * 1,
                })
                setCompanyUbication(data);
            } catch (error) {
                alert(error);
            }
        }
    }

    const fetchDepartments = async () => {
        const ubicationId = ubicationRef.current.value;
        if(ubicationId === ''){
            setCompanyDepartments([]);
            return
        } else {
            try {
                const { data } = await axios.post('/api/fetchDepartments', {
                    ubicacion_id: ubicationId * 1
                });
                setCompanyDepartments(data);
            } catch (error) {
                setCompanyDepartments([]);
                alert(error.response.data.message);
            }
        }
    }

    const changeOptions = (e) => {
        let selectedOption = e.target.value;
        if(selectedOption !== ''){
            setDisabled(false);
            const response = complaintResponse.foundClasificacionDenunciaDetalle;
            const values = response.filter(res => res.clasificacionDenuncia_id === selectedOption * 1);
            setClasificacionDetail(values);
        } else {
            setDisabled(true);
            setClasificacionDetail([])
        }
    }

    const addNewInvolved = () => {
        let allPeopleInvolved = [...allInvolved];
        let people = {};
        if(allInvolved.length === 0){
            let firstInvolved = {
                nameInvolved: involvedRef.current.parentElement.parentElement.parentElement.elements.nameInvolved.value, 
                lastNameInvolved: involvedRef.current.parentElement.parentElement.parentElement.elements.lastNameInvolved.value, 
                positionInvolved: involvedRef.current.parentElement.parentElement.parentElement.elements.positionInvolved.value,
                involvedInfractor: involvedRef.current.parentElement.parentElement.parentElement.elements.involvedInfractor.checked,
                involvedWitness: involvedRef.current.parentElement.parentElement.parentElement.elements.involvedTestigo.checked,
            }       
            setAllInvolved([firstInvolved]);
            setClicks([1]);
            return
        } else if(allInvolved.length > 0){
            for(let newInvolved of clicks){
                let nameInvolved = involvedRef.current.parentElement.parentElement.parentElement.elements[`nameInvolved${newInvolved}`].value;
                let lastNameInvolved = involvedRef.current.parentElement.parentElement.parentElement.elements[`lastNameInvolved${newInvolved}`].value;
                let positionInvolved = involvedRef.current.parentElement.parentElement.parentElement.elements[`positionInvolved${newInvolved}`].value;
                let involvedInfractor = involvedRef.current.parentElement.parentElement.parentElement.elements[`involvedInfractor${newInvolved}`].checked;
                let involvedWitness = involvedRef.current.parentElement.parentElement.parentElement.elements[`involvedTestigo${newInvolved}`].checked;
                let newInvolvedPeople = {
                    nameInvolved,
                    lastNameInvolved,
                    positionInvolved,
                    involvedInfractor,
                    involvedWitness
                }
                people = newInvolvedPeople
            }

            allPeopleInvolved = [...allInvolved, people];

            for(let i = 0; i < allPeopleInvolved.length; i++){
                if(i === allPeopleInvolved.length - 1){
                    let number = [...clicks];
                    let lastNumArray = number.pop() || 0;
                    let lastNumber = lastNumArray + 1;
                    setClicks([...clicks, lastNumber]);
                    setAllInvolved(allPeopleInvolved);
                    break
                } else {
                    console.log("")
                }
            }
        } else {
            console.log("")
        }
    }

    const addNewPic = () => {
        let allPicturesUploaded = [...allPictures];
        let newPictures = {};
        if(allPictures.length === 0){
            let firstPhoto = involvedPhoto.current.files[0];
            setAllPictures([firstPhoto]);
            setNewPics([1]);
        } else if(allPictures.length > 0){
            newPics.forEach(pic => {
                let newInvolvedPic = involvedPhoto.current.form.elements[`fileImage${pic}`].files[0];
                newPictures = newInvolvedPic;
            })

            allPicturesUploaded = [...allPictures, newPictures];

            for(let i = 0; i < allPicturesUploaded.length; i++){
                if(i === allPicturesUploaded.length - 1){
                    let number = [...newPics];
                    let lastNumArray = number.pop() || 0;
                    let lastNumber = lastNumArray + 1;
                    setNewPics([...newPics, lastNumber]);
                    setAllPictures(allPicturesUploaded);
                    break
                }
                else {
                    console.log("")
                }
            }
        } else {
            return
        }
    }

    const erasePerson = (index) => {
        let newClicks = [...clicks];
        let peopleInvolved = [...allInvolved];
        let foundIndex = clicks.findIndex(element => element === index);
        newClicks.splice(foundIndex, 1);
        if(index === newClicks.length) {
            peopleInvolved.splice(foundIndex + 1, 1);
        } else {
            peopleInvolved.splice(foundIndex, 1)
        }
        setClicks(newClicks);
        setAllInvolved(peopleInvolved);
    }

    const erasePhoto = (index) => {
        let newPhotos = [...newPics];
        let pictures = [...allPictures];
        let foundIndex = newPics.findIndex(element => element === index);
        newPhotos.splice(foundIndex, 1);
        if(index === newPhotos.length) {
            pictures.splice(foundIndex + 1, 1);
        } else {
            pictures.splice(foundIndex, 1)
        }
        setNewPics(newPhotos);
        setAllPictures(pictures)
    }

    return (
        <div className="complaint">
            <form className="complaint__form" onSubmit={sendForm}>
                <div className='partsForm'>
                    <div className='firstPart'>
                        <h3>Nueva Denuncia</h3>
                        <div className="form__section">
                            <div className="section__entries">
                                <label htmlFor="employee">Seleccione el tipo de usuario*</label>
                                <select className="select" name="employee" id="employee" required>
                                    <option value="">Seleccionar</option>
                                    {complaintResponse?.foundDenunciante.map((denunciante, index) => (
                                        <option key={index} value={denunciante.denunciante_id}>{denunciante.tipo}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form__section">
                            <span>Seleccione los datos de la ubicación y área que quiere denunciar *</span>
                            <div className="section__entries">
                                <label htmlFor="company">Empresa*</label>
                                <select className="select" name="company" id="company" onChange={fetchCompanyZone} ref={companyRef} required>
                                    <option value="">Seleccionar</option>
                                    {companies?.map((company, index) => (
                                        <option key={index} value={company.id}>{company.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="section__entries">
                                <label htmlFor="zone">Zona / Región*</label>
                                <select className="select" name="zone" id="zone" ref={zoneRef} onChange={fetchUbication} required>
                                    <option value="">Seleccionar</option>
                                    {companyZone?.map((zone, index) => (
                                        <option key={index} value={zone.zona_id}>{zone.nombre}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="section__entries">
                                <label htmlFor="ubication">Ubicación*</label>
                                <select className="select" name="ubication" id="ubication" ref={ubicationRef} onChange={fetchDepartments} required>
                                    <option value="">Seleccionar</option>
                                    {companyUbication?.map((ubication, index) => (
                                        <option key={index} value={ubication.ubicacion_id}>{ubication.ubicacion}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="section__entries">
                                <label htmlFor="area">Area/ Departamento*</label>
                                <select className="select" name="area" id="area" ref={departmentRef} required>
                                    <option value="">Seleccionar</option>
                                    {companyDepartments?.map((department, index) => (
                                        <option key={index} value={department.area_id}>{department.nombre}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="section__entries complaintTypeSelections">
                                <label htmlFor="type">Tipo de Denuncia*</label>
                                <select className="select" name="type" id="type" onChange={changeOptions} required>
                                    <option value="">Seleccionar</option>
                                    {complaintResponse?.foundClasificacionDenuncia.map((denuncia, index) => (
                                        <option key={index} value={denuncia.clasificacionDenuncia_id}>{denuncia.clasificacion}</option>
                                    ))}
                                </select>
                                <select className="select" name="type" id="type" disabled={disabled} required>
                                    <option value="">Seleccionar</option>
                                    {clasificacionDetail.map((detail, index) => (
                                        <option key={index} value={detail.clasificacionDenunciaDetalle_id}>{detail.clasificacionDetalle}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form__section" ref={involvedRef}>
                            <span>Por favor identifique a la(s) persona(s) involucrada(s) *</span>
                            <p>En caso de no conocer alguno de los datos siguientes, favor de poner SD (Sin Datos)</p>
                            <div className="section__entries">
                                <label htmlFor="nameInvolved">Nombre*</label>
                                <input type="text" id="nameInvolved" required />
                            </div>
                            <div className="section__entries">
                                <label htmlFor="lastNameInvolved">Apellido*</label>
                                <input type="text" id="lastNameInvolved" required />
                            </div>
                            <div className="section__entries">
                                <label htmlFor="positionInvolved">Puesto/ Relación*</label>
                                <input type="text" id="positionInvolved" required />
                            </div>
                            <div className="section__entries">
                                <label>El involucrado es*</label>
                                <div className='typeInvolvedContainer'>
                                    <input type="radio" name="typeInvolvedTitle" id="involvedInfractor" required />
                                    <label htmlFor="involvedInfractor">Infractor</label>
                                    <input type="radio" name="typeInvolvedTitle" id="involvedTestigo" />
                                    <label htmlFor="involvedTestigo">Testigo</label>
                                </div>
                            </div>
                            {
                                clicks.map((index) => (
                                    <div className="form__section newPersonInvolved" key={index}>
                                        <div className="section__entries">
                                            <label htmlFor="nameInvolved">Nombre:</label>
                                            <input type="text" id={`nameInvolved${index}`} required />
                                        </div>
                                        <div className="section__entries">
                                            <label htmlFor="lastNameInvolved">Apellido:</label>
                                            <input type="text" id={`lastNameInvolved${index}`} required />
                                        </div>
                                        <div className="section__entries">
                                            <label htmlFor="positionInvolved">Puesto/ Relación:</label>
                                            <input type="text" id={`positionInvolved${index}`} required />
                                        </div>
                                        <div className="section__entries">
                                            <label>El involucrado es:</label>
                                            <div className='typeInvolvedContainer'>
                                                <input type="radio" name={`typeInvolvedTitle${index}`} id={`involvedInfractor${index}`} required/>
                                                <label htmlFor="involvedInfractor">Infractor</label>
                                                <input type="radio" name={`typeInvolvedTitle${index}`} id={`involvedTestigo${index}`} />
                                                <label htmlFor="involvedTestigo">Testigo</label>
                                            </div>
                                        </div>
                                        <button className='erasePersonButton' onClick={() => erasePerson(index)}>Borrar involucrado</button>
                                    </div>
                                ))
                            }
                            <span className='addNewInvolvedSpan' onClick={addNewInvolved}>Agregar otro involucrado</span>
                        </div>
                        <div className="form__section">
                            <span>Describa la situación y detalles del incidente*</span>
                            <div className="section__entries">
                                <textarea name="textDetails" id="textDetails" cols="30" rows="10" required></textarea>
                            </div>
                        </div>
                        <div className="form__section">
                            <span>Desea agregar archivos (Opcional)</span>
                            <div className="section__entries">
                                <label htmlFor="fileImage">Agregar archivo o evidencia</label>
                                <input type="file" name="fileImage" id="fileImage" accept="image/*" ref={involvedPhoto} />
                            </div>
                            {newPics.map(index => (
                                <div className="section__entries" key={index}>
                                    <label htmlFor={`fileImage${index}`}>Agregar archivo o evidencia</label>
                                    <input type="file" name={`fileImage${index}`} id={`fileImage${index}`} accept="image/*" required></input>
                                    <span className='erasePhotoButton' onClick={() => erasePhoto(index)}>X</span>
                                </div>
                            ))}
                            <span className='addNewPicSpan' onClick={addNewPic}>Agregar otro archivo</span>
                        </div>
                    </div>
                    <div className='secondPart'>
                        <img src={megaphone} alt="mgephoneImage" />
                        <div className="form__section">
                            <span>Si lo deseas, escribe tus datos (Opcional)</span>
                            <div className="section__entries">
                                <label htmlFor="witnessName">Nombre</label>
                                <input type="text" id="witnessName" />
                            </div>
                            <div className="section__entries">
                                <label htmlFor="witnessLastName">Apellido</label>
                                <input type="text" id="witnessLastName" />
                            </div>
                            <div className="section__entries">
                                <label htmlFor="witnessPosition">Puesto</label>
                                <input type="text" id="witnessPosition" />
                            </div>
                            <div className="section__entries">
                                <label htmlFor="witnessNumber">Teléfono</label>
                                <input type="text" id="witnessNumber" />
                            </div>
                            <div className="section__entries">
                                <label htmlFor="witnessMail">Correo</label>
                                <input type="text" id="witnessMail" />
                            </div>
                            <div className="section__entries">
                                <label htmlFor="witnessAffected">¿Es usted afectado directo?</label>
                                <select name="witnessAffected" id="witnessAffected">
                                    <option value="">--</option>
                                    <option value="si">Si</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                        </div>
                        <div className="form__section">
                            <span>Información adicional (Opcional)</span>
                            <div className="section__entries">
                                <label htmlFor="additionalQuestion1">¿Es la primera vez que utiliza la línea de denuncia?</label>
                                <select name="additionalQuestion1" id="additionalQuestion1">
                                    <option value="">--</option>
                                    <option value="si">Si</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                            <div className="section__entries">
                                <label htmlFor="additionalQuestion2">¿Cómo se enteró de la línea de denuncia?*</label>
                                <select name="additionalQuestion2" id="additionalQuestion2" required>
                                    <option value="">Seleccionar</option>
                                    {complaintResponse?.foundMedio.map((medio, index) => (
                                        <option key={index} value={medio.medio_id}>{medio.tipo}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="submit" className='submitButtonForm' disabled={disableSubmit}>{!disableSubmit ? 'Enviar' : 'Enviando'}</button>
            </form>
        </div>
    )
}

export default Denuncia
