import React from 'react'
import { useHistory } from 'react-router-dom'
import axios from '../components/axios';
import { COMPANIES_NAMES } from '../utils/actions';
import { useAppState } from '../utils/AppContext';

function Main() {
    // eslint-disable-next-line
    const [{}, dispatch] = useAppState();
    const history = useHistory();

    const submitCompanyId = async (e) => {
        e.preventDefault()
        const companyId = e.target.companyId.value;
        const allCompanies = [];
        if(companyId !== '') {
            try {
                const { data } = await axios.post('/api/getCompanyId', {
                    companyId: e.target.companyId.value
                });
                for(let info of data.foundCompanies){
                    const companyData = {
                        name: info.nombre,
                        id: info.empresa_id
                    }
                    allCompanies.push(companyData);
                }
                dispatch({
                    type: COMPANIES_NAMES,
                    companies: allCompanies
                });
                history.push(`/company/${companyId}`)
            } catch (err) {
                alert('ID incorrecto, porfavor ingresa el ID de tu empresa');
            }
        } else {
            alert('Por favor ingresa el ID de tu empresa')
        }
    }

    return (
        <div className="mainPage">
            <form className='mainForm' onSubmit={submitCompanyId}>
                <h1>Escribe el ID de tu compañía</h1>
                <input type="text" name="companyId" id="companyId" required/>
            </form>
        </div>
    )
}

export default Main
