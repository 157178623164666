import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useAppState } from '../utils/AppContext';
import axios from '../components/axios';

function AdminEditZones() {
    const [{ userInfo }] = useAppState();

    const [zones, setZones] = useState();
    const [createNewZone, setCreateNewZone] = useState(false);

    const history = useHistory();
    
    useEffect(() => {
        if(!userInfo.status) {
            alert('Por favor ingresa con tu correo y contraseña');
            history.replace('/bhr-adminSignin');
        } else {
            const fetchGroups = async () => {
                try {
                    const response = await axios.get('/api/fetchAllZones');
                    setZones(response.data);
                } catch (error) {
                    alert(error.response.data.message);
                    history.push('/bhr-adminDashboard');
                }
            }
            fetchGroups()
        }

        return () => userInfo.status;
    }, [history, userInfo.status])
    
    const createZone = async (e) => {
        e.preventDefault();
        try {
            const zoneName = e.target.createZoneInput.value;
            const { data } = await axios.post('/api/createNewZone', {
                zoneName
            });
            alert(data);
            history.replace('/bhr-adminDashboard');
        } catch (error) {
            alert(error.response.data.message);
            history.push('/bhr-adminDashboard');
        }
    }

    return (
        <div className='editZones__container'>
            {zones?.map((zone, index) => (
                <input className='editZones__inputs' key={index} type="text" defaultValue={zone.nombre} data-id={zone.zonasOpciones_id} disabled />
            ))}
            <button className='editZones__button' onClick={() => setCreateNewZone(!createNewZone)}>{!createNewZone ? "Crear una nueva zona" : "Cancelar nueva zona"}</button>
            {createNewZone &&
                <form onSubmit={createZone} className="editZones__form">
                    <input className='editZones__formInput' type="text" name='createZoneInput' required />
                    <button className='editZones__formButton' type="submit">Crear zona</button>
                </form>
            }
        </div>
    )
}

export default AdminEditZones