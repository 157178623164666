import './css/App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from './components/Footer';
import Header from './components/Header';
import Denuncia from './pages/Denuncia';
import DenunciaId from './pages/DenunciaId';
import AdminSignIn from './pages/AdminSignIn';
import AdminDashboard from './pages/AdminDashboard';
import Main from './pages/Main';
import Company from './pages/Company';
import AdminNewCompany from './pages/AdminNewCompany';
import AdminEditCompany from './pages/AdminEditCompany';
import AdminDeleteCompany from './pages/AdminDeleteCompany';
import AdminEditZones from './pages/AdminEditZones';
import AdminEditDepartments from './pages/AdminEditDepartments';
import AdminEditUsers from './pages/AdminEditUsers';
import AdminDeleteComplaint from './pages/AdminDeleteComplaint';

function App() {
  return (
    <Router>
      <div className="app">
        <Header />
        <Switch>
          <Route exact path="/denuncia/:id">
            <DenunciaId />
          </Route>
          <Route path="/:companyId/denuncia">
            <Denuncia />
          </Route>
          <Route path="/company/:companyId">
            <Company />
          </Route>
          <Route path="/bhr-adminDashboard/editUsers">
            <AdminEditUsers />
          </Route>
          <Route path="/bhr-adminDashboard/editDepartments">
            <AdminEditDepartments />
          </Route>
          <Route path="/bhr-adminDashboard/editZones">
            <AdminEditZones />
          </Route>
          <Route path="/bhr-adminDashboard/deleteComplaint">
            <AdminDeleteComplaint />
          </Route>
          <Route path="/bhr-adminDashboard/deleteCompany">
            <AdminDeleteCompany />
          </Route>
          <Route path="/bhr-adminDashboard/editCompany">
            <AdminEditCompany />
          </Route>
          <Route path="/bhr-adminDashboard/newCompany">
            <AdminNewCompany />
          </Route>
          <Route path="/bhr-adminDashboard">
            <AdminDashboard />
          </Route>
          <Route path="/bhr-adminSignin">
            <AdminSignIn />
          </Route>
          <Route path="/">
            <Main />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
